import styled from 'styled-components'

export const PaginationControlContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  grid-column: 2 / 3;
  margin-top: 20px;
`

export const ArrowContainer = styled.button`
  background-color: unset;
  width: 60px;
  height: 40px;
  flex-basis: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ flipped }) => (flipped ? 'rotate(-180deg)' : 'unset')};

  :hover {
    svg {
      fill: var(--highlight-blue);
    }
  }
`

export const PageButton = styled.button`
  background-color: unset;
  width: 40px;
  height: 40px;
  flex-basis: 40px;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--content-background-dark-blue);

  :hover {
    color: var(--highlight-blue);
  }

  :disabled {
    background-color: var(--highlight-blue);
    color: white;
  }
`

export const MorePagesIndicator = styled.div`
  background-color: white;
  width: 40px;
  height: 40px;
  flex-basis: 40px;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--content-background-dark-blue);
`

import styled from 'styled-components'

export const FilterToggleButton = styled.button`
  grid-column: 2 / 3;
  width: max-content;
  padding: 0 2.8rem;
  background-color: var(--highlight-blue);
  height: 60px;
  color: var(--content-background-light);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  user-select: none;
  border-radius: 10px;
  margin: 20px auto 0;

  :hover {
    background-color: var(--content-background-dark-blue);
  }
`

import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'

export const FormContainer = styled.section`
  grid-column: 2 / 3;
  margin-top: 13px;
`

export const FilterForm = styled.form`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 950px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${DEVICE.desktop} {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`

export const FilterCategoryColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 40px 0;
`

export const FilterGroupHeading = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  color: var(--content-background-dark-blue);
  margin-bottom: 10px;
`

export const LabelCheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const CheckboxLabel = styled.label`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.35;
  color: var(--content-background-dark-blue);
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  flex-basis: 20px;
  flex-shrink: 0;
  background: white;
  border: 1px solid var(--highlight-blue);
  border-radius: 2px;

  :hover {
    cursor: pointer;
  }
`

export const CheckboxLabelPair = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 13px;

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.35;
    color: var(--content-background-dark-blue);
    padding-left: 10px;
    user-select: none;

    :hover {
      cursor: pointer;
    }

    @media ${DEVICE.tabletL} {
      font-size: 1.4rem;
    }
  }
`

import React, { useState, useEffect } from 'react'

import * as Styled from './styles/PaginationControl.styles'
import { PaginationArrow } from './Icons'
import WithLocation from '../util/WithLocation'

const MAX_PAGES_IN_CONTROL = 4

const PaginationControl = (
  { search, itemCount, itemsPerPage = 20,
    setFirstItemForPage, setLastItemForPage,
    paginationChangeAction }
) => {
  const [activePage, setActivePage] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(null)
  const [incrementor, setIncrementor] = useState(null)
  const [paginationRange, setPaginationRange] = useState(null)
  const [maxItems, setMaxItems] = useState(0)

  useEffect(() => {
    setActivePage(search.page ? parseInt(search.page, 10) : 1)
  }, [search.page])

  // initialize number of pages state
  useEffect(() => {
    const pageCount = Math.ceil(itemCount / itemsPerPage)
    setNumberOfPages(pageCount)
    setMaxItems(Math.min(MAX_PAGES_IN_CONTROL, pageCount))
    if (activePage !== 1 && (activePage - 1) * itemsPerPage > itemCount && search.page !== '1') {
      // console.log(activePage, itemsPerPage, itemCount)
      paginationChangeAction(1)
    }
  }, [itemCount])

  // init pagination range state after determining number of pages
  useEffect(() => {
    if (!numberOfPages) { return }
    let i = activePage
    while (i + maxItems > numberOfPages + 1) { i -= 1 }
    let counter = 1
    const tempArray = []
    while (counter <= maxItems) {
      tempArray.push(i)
      i++
      counter++
    }
    setPaginationRange([...tempArray])
  }, [numberOfPages, maxItems])

  useEffect(() => {
    if (!activePage) { return }
    setFirstItemForPage((activePage - 1) * itemsPerPage)
    setLastItemForPage(activePage * itemsPerPage)
  }, [activePage])

  // update paginationRange
  useEffect(() => {
    if (!incrementor) { return }

    // No going down from 1
    if (paginationRange[0] <= 1 && incrementor === -1) {
      setIncrementor(null)
      if (activePage > 1) { paginationChangeAction(activePage - 1) }
      return
    }
    // No going up from the last item
    if (paginationRange[paginationRange.length - 1] >= numberOfPages && incrementor === 1) {
      setIncrementor(null)
      if (activePage < numberOfPages) { paginationChangeAction(activePage + 1) }
      return
    }

    let i = 0
    while (i < maxItems) {
      paginationRange[i] += incrementor
      i++
    }

    setIncrementor(null)
    paginationChangeAction(activePage + incrementor)
  }, [incrementor])

  const arrowClicked = (increment) => {
    setIncrementor(increment)
  }

  const pageButtonClicked = (page) => {
    paginationChangeAction(page)
  }

  return (
    <Styled.PaginationControlContainer>
      { paginationRange && paginationRange.length > 1 &&
        <Styled.ArrowContainer
          onClick={() => arrowClicked(-1)}
        >
          <PaginationArrow />
        </Styled.ArrowContainer>
      }
      {
        paginationRange && paginationRange.length > 1
        && paginationRange[0] > 1 &&
        <Styled.PageButton
          as='div'
          disabled={false}
        >
          . . .
        </Styled.PageButton>
      }
      { paginationRange && paginationRange.length > 1 && paginationRange.map(page =>
        <Styled.PageButton
          key={page}
          disabled={page === activePage}
          onClick={() => pageButtonClicked(page)}
        >
          {page}
        </Styled.PageButton>
      )
      }
      {
        paginationRange && paginationRange.length > 1 && paginationRange[paginationRange.length - 1] < numberOfPages &&
        <Styled.PageButton
          as='div'
          disabled={false}
        >
          . . .
        </Styled.PageButton>
      }
      { paginationRange && paginationRange.length > 1 &&
        <Styled.ArrowContainer
          onClick={() => arrowClicked(1)}
          flipped
        >
          <PaginationArrow />
        </Styled.ArrowContainer>
      }
    </Styled.PaginationControlContainer>

  )
}

export default WithLocation(PaginationControl)